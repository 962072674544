import { Link } from 'gatsby';
import { ElementType, FC, ReactNode } from 'react';
import tw, { styled } from 'twin.macro';
import {
  ChildrenAndCustomCssProps,
  CustomCssProps,
} from '~/_shared/config/types';

export const Bold = tw.span`font-bold`;
export const Italic = tw.span`italic`;
export const Uppercase = tw.span`uppercase`; // To avoid screen readers from reading caps as acronyms

export const JELMYTO: FC = (props) => {
  return <Uppercase>Jelmyto</Uppercase>;
};
export const OLYMPUS: FC = (props) => {
  return <Uppercase>Olympus</Uppercase>;
};

export const ClickHereLink = tw.a`text-brand-aqua font-semibold no-underline hocus:text-brand-magenta`;
// Gets formatted improperly upon safe so:
// prettier-ignore
export const InternalLink = tw(Link)`text-brand-aqua font-semibold no-underline hocus:text-brand-magenta`;

export const UL = tw.ul`pl-4`;

export const LI = tw.li`marker:(inline-block w-[5px] h-[5px] [border-radius: 50%] bg-brand-magenta-100 mb-[3px] mr-2)
`;

export const NoWrap = tw.span`whitespace-nowrap`;

// for Section Headings on internal pages
export const SectionHeading = tw.h2`font-primary font-bold text-brand-purple [font-size: 1.75rem] [line-height: 2rem] mt-0 mb-2`;
// H3 below section heading
export const SubHeading = tw.h3`font-primary font-semibold uppercase text-brand-purple [font-size: 1.125rem] [line-height: 111%] mt-0 mb-2`;
// Gunmetal h4
export const ProductTitle = tw.h4`font-primary text-brand-gunmetal-100 m-0 font-bold text-base [font-size: 1.1rem] mb-[-12px]`;

export const Footnotes = tw.div`text-xs leading-[18px] text-brand-grey-dk`;

// Standard 40px space between elements
export const LargeSpacer = tw.div`h-10 md:h-20`;
export const Spacer = tw.div`h-4 md:h-10`;
export const SmallSpacer = tw.div`h-2.5 md:h-5`;

// For "Eyebrow"-style text above page headings
export const SmallPurpleP = tw.p`text-brand-purple text-[20px]`;

type BlockQuoteProps = CustomCssProps & {
  children: ReactNode;
};

/** Pass in your children wrapped in a <div> to avoid flex weirdness */
export const BlockQuote: FC<BlockQuoteProps> = ({ children, customCss }) => {
  return (
    <Quote css={customCss}>
      <TextWrapper>{children}</TextWrapper>
    </Quote>
  );
};
const TextWrapper = tw.div`w-full font-bold text-base [letter-spacing: 0.5px] before:(bg-gradient-to-r from-brand-magenta to-brand-purple block mr-4 w-full h-full max-w-[0.5rem] [border-radius: 0.5rem] ) flex`;
const Quote = tw.blockquote`flex m-0  text-brand-purple`;

// WIP: Brand typograpthy based on Figma style guide
export const styleResets = tw`m-0! p-0!`;
export type TypographyProps = ChildrenAndCustomCssProps & {
  as?: ElementType;
  bold?: boolean;
  semibold?: boolean;
};
/**
 * Brand h1 according to style guide
 * @prop as Accepts any valid HTMLElement to use in place of h1, maintaining styles
 */
export const BrandH1: FC<TypographyProps> = ({ as, children, customCss }) => {
  return (
    <BrandedH1 as={as} css={[styleResets, customCss]}>
      {children}
    </BrandedH1>
  );
};
const BrandedH1 = styled.h1(({ light }: { light?: boolean }) => [
  tw`font-bold font-primary text-[28px] [line-height: 32px] text-brand-purple`,
  light && tw`font-normal text-[26px] [line-height:28px ]`,
]);

/**
 * Brand h2
 * @prop as Accepts any valid HTMLElement to use in place of h1, maintaining styles
 */
export const BrandH2: FC<TypographyProps> = ({ as, children, customCss }) => {
  return (
    <BrandedH2 as={as} css={[styleResets, customCss]}>
      {children}
    </BrandedH2>
  );
};
const BrandedH2 = styled.h2(
  () =>
    tw`font-semibold font-primary text-[18px] [line-height: 20px] [letter-spacing: .88px] text-brand-purple uppercase`,
);

/**
 * Brand h3
 * @prop as Accepts any valid HTMLElement to use in place of h1, maintaining styles
 */
export const BrandH3: FC<TypographyProps> = ({ as, children, customCss }) => {
  return (
    <BrandedH3 as={as} css={[styleResets, customCss]}>
      {children}
    </BrandedH3>
  );
};
const BrandedH3 = styled.h3(
  () =>
    tw`font-bold font-primary text-[18px] [line-height: 24px] [letter-spacing: .56px] text-brand-purple`,
);

/**
 * Brand P
 * @prop as Accepts any valid HTMLElement to use in place of h1, maintaining styles
 */
export const BrandP: FC<TypographyProps & { bold?: boolean }> = ({
  as,
  children,
  customCss,
}) => {
  return (
    <BrandedP as={as} css={[styleResets, customCss]}>
      {children}
    </BrandedP>
  );
};
const BrandedP = styled.p(({ bold }: { bold?: boolean }) => [
  tw`font-normal font-primary text-[16px] [line-height: 24px] [letter-spacing: .5px] text-brand-gunmetal-100`,
  bold && tw`font-bold [letter-spacing: 0]`,
]);

export const LinkText = styled.span(
  () =>
    tw`[text-decoration:none] text-right text-brand-aqua relative font-bold`,
);

export const BrandStrong = tw.strong`[font-weight: 800]`;

export const RBall = ({ customCss }: CustomCssProps) => (
  <sup css={customCss}>®</sup>
);
